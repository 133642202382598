<template>
  <div>
    <section class="section pb-0 mb-0 mt-0 mt-md-auto section-top-media">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-12">
            <div v-if="process !== null" class="card work-details rounded bg-white border-5">
              <div class="card-header bg-soft-primary align-middle d-flex justify-content-between">
                <div>
                  <span class="h5 mb-0">{{ process.modality }} nº {{ process.bidding_number }}</span>
                  <small class=" ml-1 badge badge-pill" :class="process.bg_class"> {{ process.status }}</small>
                </div>
<!--                <div>-->
<!--                  <a href="#" class="btn btn-sm btn-success mouse-down"><i class="fa-regular fa-eye mr-1"></i>Ver Disputa</a>-->
<!--                </div>-->
              </div>

              <div class="card-body pt-2">
                <div class="row mb-2 px-2 border-bottom text-primary font-weight-bold">
                  Dados do Processo
                </div>
                <dl class="row mb-0">
                  <dt class="col-md-4 col-5 text-muted small small">Processo Adm. Nº:</dt>
                  <dd class="col-md-8 col-7 mb-1 text-muted small font-weight-light"> {{ process.number_process }}</dd>

                  <dt class="col-md-4 col-5 text-muted small">Modo de Disputa:</dt>
                  <dd class="col-md-8 col-7 mb-1 text-muted small font-weight-light">{{ process.dispute_mode }}</dd>

                  <dt class="col-md-4 col-5 text-muted small">Critério de Julgamento:</dt>
                  <dd class="col-md-8 col-7 mb-1 text-muted small font-weight-light">{{ process.judgment_criterion }}</dd>

                  <dt class="col-md-4 col-5 text-muted small">Benefício de Regionalidade:</dt>
                  <dd class="col-md-8 col-7 mb-1 text-muted small font-weight-light">{{ process.regional_benefit }}</dd>

                  <dt class="col-md-4 col-5 text-muted small">Agente de Contratação / Pregoeiro(a):</dt>
                  <dd class="col-md-8 col-7 mb-1 text-muted small font-weight-light">{{ process.bidding_agent }}</dd>

                  <dt class="col-md-4 col-5 text-muted small">Objeto:</dt>
                  <dd class="col-md-8 col-7 mb-1 text-muted small font-weight-light text-justify">{{ process.object_details }}</dd>

                </dl>

                <div class="row mb-2 mt-2 px-2 border-bottom text-primary font-weight-bold">
                  Datas / Prazos
                </div>
                <dl class="row mb-0">
<!--                  <dt class="col-md-4 col-5 text-muted small">Publicação:</dt>-->
<!--                  <dd class="col-md-8 col-7 mb-1 text-muted small font-weight-light text-justify">{{ date_to_br_in_full(process.publication_date) }}</dd>-->

                  <dt class="col-md-4 col-5 text-muted small">Início do Envio de Propostas:</dt>
                  <dd class="col-md-8 col-7 mb-1 text-muted small font-weight-light text-justify">{{ datetime_to_br(process.start_date_send_proposal) }}</dd>

                  <dt class="col-md-4 col-5 text-muted small">Abertura:</dt>
                  <dd class="col-md-8 col-7 mb-1 text-muted small font-weight-light text-justify">{{ datetime_to_br(process.start_date_dispute) }}</dd>

                </dl>

                <div class="row mb-2 mt-2 px-2 border-bottom text-primary font-weight-bold">
                  Edital
                </div>
                <dl class="row mb-0">
                  <dt class="col-md-4 col-5 text-muted small">Edital:</dt>
                  <dd class="col-md-8 col-7 mb-1 text-muted small font-weight-light text-justify">
                    <a class="small text-primary" target="_blank" :href="process.bidding_notice_file">Ver Edital</a>
                  </dd>

                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section v-if="process !== null" class="section pt-2 mt-2">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <b-tabs content-class="mt-2" pills nav-class="bg-white shadow">
              <b-tab title-item-class="m-1">
                <template #title>
                  <span class="nav-link py-2 px-5 rounded">
                    <div class="text-center">
                      <h6 class="mb-0">Lotes / Itens</h6>
                    </div>
                  </span>
                </template>
                <div class="row">
                  <div class="col-12">
                    <div>
                      <table id="table-lots" class="table mb-0 table-sm small">
                        <thead>
                          <tr>
                            <th scope="col" class="text-center" style="width: 5%">Nº</th>
                            <th scope="col" class="text-center" style="width: 65%">Descrição</th>
                            <th scope="col" class="text-center" style="width: 15%">Valor Estimado</th>
                            <th scope="col" class="text-center" style="width: 15%">Situação</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(lot, index) in process.lots" :key="`lot-${index}`">
                            <td class="text-center small">{{ lot.lotNumber }}</td>
                            <td class="text-justify small">{{ lot.lotDescription }}</td>
                            <td class="text-right small">{{ lot.amountTotalLot }}</td>
                            <td class="text-center small">{{ lot.lotStatus }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!--end row-->
              </b-tab>
<!--              <b-tab title-item-class="m-1">-->
<!--                <template #title>-->
<!--                  <span class="nav-link py-2 px-5 rounded">-->
<!--                    <div class="text-center">-->
<!--                      <h6 class="mb-0">Avisos</h6>-->
<!--                    </div>-->
<!--                  </span>-->
<!--                </template>-->
<!--                <table class="table">-->
<!--                  <tbody>-->
<!--                  <tr>-->
<!--                    <td style="width: 100px">Color</td>-->
<!--                    <td class="text-muted">Red, White, Black, Orange</td>-->
<!--                  </tr>-->

<!--                  <tr>-->
<!--                    <td>Material</td>-->
<!--                    <td class="text-muted">Cotton</td>-->
<!--                  </tr>-->

<!--                  <tr>-->
<!--                    <td>Size</td>-->
<!--                    <td class="text-muted">S, M, L, XL, XXL</td>-->
<!--                  </tr>-->
<!--                  </tbody>-->
<!--                </table>-->
<!--              </b-tab>-->
<!--              <b-tab title-item-class="m-1">-->
<!--                <template #title>-->
<!--                  <span class="nav-link py-2 px-5 rounded">-->
<!--                    <div class="text-center">-->
<!--                      <h6 class="mb-0">Solicitações</h6>-->
<!--                    </div>-->
<!--                  </span>-->
<!--                </template>-->
<!--                <table class="table">-->
<!--                  <tbody>-->
<!--                  <tr>-->
<!--                    <td style="width: 100px">Color</td>-->
<!--                    <td class="text-muted">Red, White, Black, Orange</td>-->
<!--                  </tr>-->

<!--                  <tr>-->
<!--                    <td>Material</td>-->
<!--                    <td class="text-muted">Cotton</td>-->
<!--                  </tr>-->

<!--                  <tr>-->
<!--                    <td>Size</td>-->
<!--                    <td class="text-muted">S, M, L, XL, XXL</td>-->
<!--                  </tr>-->
<!--                  </tbody>-->
<!--                </table>-->
<!--              </b-tab>-->
<!--              <b-tab title-item-class="m-1">-->
<!--                <template #title>-->
<!--                  <span class="nav-link py-2 px-5 rounded">-->
<!--                    <div class="text-center">-->
<!--                      <h6 class="mb-0">Atas</h6>-->
<!--                    </div>-->
<!--                  </span>-->
<!--                </template>-->
<!--                <table class="table">-->
<!--                  <tbody>-->
<!--                  <tr>-->
<!--                    <td style="width: 100px">Color</td>-->
<!--                    <td class="text-muted">Red, White, Black, Orange</td>-->
<!--                  </tr>-->

<!--                  <tr>-->
<!--                    <td>Material</td>-->
<!--                    <td class="text-muted">Cotton</td>-->
<!--                  </tr>-->

<!--                  <tr>-->
<!--                    <td>Size</td>-->
<!--                    <td class="text-muted">S, M, L, XL, XXL</td>-->
<!--                  </tr>-->
<!--                  </tbody>-->
<!--                </table>-->
<!--              </b-tab>-->
            </b-tabs>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import BiddingSystemProcessService from "@/services/BiddingSystemProcessService";
import date_to_br_in_full from "@/utils/date_to_br_in_full";
import datetime_to_br from "@/utils/datetime_to_br";

export default {
  props: ['processId'],
  data() {
    return {
      process: null,
      date_to_br_in_full: date_to_br_in_full,
      datetime_to_br: datetime_to_br,
    }
  },
  created() {
    this.getProcess()
  },
  methods: {
    async getProcess() {
      const params = {
        id: this.processId,
        origin: window.location.host
      }

      const response = await BiddingSystemProcessService.getAll(params)
      const {data} = response.data

      const process = data

      this.process = process
    }
  }
}
</script>

<style scoped>
@media (max-width: 1023px) {
  .section-top-media  {
    padding-top: 90px !important;
  }
}
</style>
